import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  AppState,
  ComponentSynchronizationStatusChanged,
  LoadComponentVersionsSuceeded,
  LoadComponents,
  LoadProjects,
  LoadSettings,
  RemoveInstancesFromState,
  selectAppState,
} from '../../state';
import { LoggingService } from '../logging-service/logging.service';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';
import {
  CheckoutBlobFileSynchronizedSubscriptionGQL,
  ComponentChildSubscriptionGQL,
  ComponentSubscriptionGQL,
} from '@genetpdm/model/graphql';
import { handleGraphQlErrors } from '../utility/graphql-utilities';
import { ComponentVersion, SynchronizingComponent } from '@genetpdm/model';
import { hashObject } from '../../utils/hash-helper/hash-helper';
import { dictionaryToArray } from '../../utils/array-helper/array-helper';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private metadataChangedSubscription: Subscription;
  private instancesChangedSubscription: Subscription;
  private synchronizationSubscription: Subscription; //ToDo implement Hasura Subscription

  constructor(
    private store: Store,
    private logger: LoggingService,
    private componentSubscription: ComponentSubscriptionGQL,
    private componentChildrenSubscription: ComponentChildSubscriptionGQL,
    private checkoutSynchronizedSubscription: CheckoutBlobFileSynchronizedSubscriptionGQL,
  ) {}

  //Initializes the state for the whole application
  public initializeState() {
    this.store.dispatch(LoadSettings());
    this.store.dispatch(LoadProjects());

    //Live Updates (children and metadata changes)
    this.getState$().subscribe(this.resubscribeLiveState.bind(this));
  }

  private getState$(): Observable<AppState> {
    return this.store.pipe(
      select(selectAppState),
      debounceTime(10000),
      distinctUntilChanged(),
    );
  }

  private resubscribeLiveState(state: AppState) {
    if (this.metadataChangedSubscription!) {
      this.metadataChangedSubscription.unsubscribe();
    }
    if (this.instancesChangedSubscription!) {
      this.instancesChangedSubscription.unsubscribe();
    }
    if (this.synchronizationSubscription!) {
      this.synchronizationSubscription.unsubscribe(); //TODO: implement sync subscription
    }

    this.metadataChangedSubscription = this.stateChangedSubscription$(
      state.components.ids as string[],
    ).subscribe((result) => {
      this.updateComponentVersions(
        result.data.cad_component_version as ComponentVersion[],
      );
    });

    this.instancesChangedSubscription = this.stateChangedChildSubscription$(
      state.expandedComponentIds as string[],
    ).subscribe((result) => {
      this.updateChildComponents(
        result.data.cad_instance as LiveInstance[],
        state,
      );
    });

    this.synchronizationSubscription = this.checkoutSynchronizedSubscription$(
      dictionaryToArray(state.componentVersions.entities)
        .filter((v) => v.checked_out)
        .map((v) => v.component_id)
    ).subscribe((result) => {
      const synchronizingComponents = result.data.view_checkout_blob_file_synchronized.map(
        (c) => c as SynchronizingComponent
      );

      const currentTime = new Date().toLocaleTimeString();
      console.log(`[state.service] [${currentTime}] Synchronizing components:`,
        synchronizingComponents.map(c => `${c.component_id}, synchronized: ${c.synchronized}`));

      // Aktualisiere den Zustand basierend auf den tatsächlichen synchronisierten Werten
      this.updateSynchronizingState(synchronizingComponents);
    });

  }

  private updateChildComponents(components: LiveInstance[], state: AppState) {
    const stateInstanceIds = state.instances.ids as string[];

    const addedChildren = this.checkForAddedChildren(
      components,
      stateInstanceIds,
    );

    if (addedChildren.length > 0) {
      this.logger.logDebug(
        `[Live State Service]: Adding ${addedChildren.length} children to state.`,
      );
      this.store.dispatch(
        LoadComponents({
          ids: addedChildren.map((instance) => instance.right_id),
        }),
      );
    }

    const expandedIds = state.expandedComponentIds;
    const stateExpandedInstances = stateInstanceIds.filter((id) =>
      expandedIds.includes(state.instances.entities[id].left_id),
    );

    const deletedChildren = this.checkForDeletedChildren(
      components,
      stateInstanceIds,
      stateExpandedInstances,
    );

    if (deletedChildren.length > 0) {
      this.logger.logDebug(
        `[Live State Service]: Deleting ${deletedChildren.length} children from state.`,
      );
      this.store.dispatch(
        RemoveInstancesFromState({
          instance_ids: deletedChildren,
        }),
      );
    }

    const structuralChangedChildren = this.checkForStructureChanges(
      components,
      state,
    );

    if (structuralChangedChildren.length > 0) {
      this.logger.logDebug(
        `[Live State Service]: Adding ${structuralChangedChildren.length} structural changes to state.`,
      );
      this.store.dispatch(
        RemoveInstancesFromState({
          instance_ids: structuralChangedChildren.map((item) => item.id),
        }),
      );

      this.store.dispatch(
        LoadComponents({
          ids: structuralChangedChildren.map((instance) => instance.right_id),
        }),
      );
    }
  }

  private checkForStructureChanges(
    components: LiveInstance[],
    state: AppState,
  ) {
    const structuralChanges: LiveInstance[] = [];
    components.forEach((liveComponent) => {
      const stateComponent = state.instances.entities[liveComponent.id];
      if (stateComponent?.left_id != liveComponent.left_id)
        structuralChanges.push(liveComponent);
    });
    return structuralChanges;
  }

  private checkForDeletedChildren(
    components: LiveInstance[],
    stateInstanceIds: string[],
    expandedIds: string[],
  ): string[] {
    // State has data that is removed in LiveInstance
    const liveComponentIds = components.map((items) => items.id);
    return expandedIds.filter((id) => !liveComponentIds.includes(id));
  }

  private checkForAddedChildren(
    components: LiveInstance[],
    stateInstanceIds: string[],
  ): LiveInstance[] {
    return components.filter((item) => {
      return !stateInstanceIds.includes(item.id);
    });
  }

  private currentComponentVersionsHash: string = '';

  private updateComponentVersions(componentVersions: ComponentVersion[]) {
    const hash = hashObject(componentVersions);
    if (this.currentComponentVersionsHash === hash) return;
    this.currentComponentVersionsHash = hash;

    this.logger.logDebug('[Live State Service]: Updating component versions');

    this.store.dispatch(
      LoadComponentVersionsSuceeded({ components: componentVersions }),
    );
  }

  private currentSynchronizingComponentVersionsHash: string = '';

  private updateSynchronizingState(synchronizingComponentVersions: SynchronizingComponent[]) {
    const hash = hashObject(synchronizingComponentVersions);
    if (this.currentSynchronizingComponentVersionsHash === hash) return;
    this.currentSynchronizingComponentVersionsHash = hash;

    this.logger.logDebug('[Live State Service]: Updating sync states of component versions');

    this.store.dispatch(
      ComponentSynchronizationStatusChanged({components: synchronizingComponentVersions})
    );
  }

  private stateChangedChildSubscription$(parentIds: string[]) {
    return this.componentChildrenSubscription
      .subscribe({
        ids: parentIds,
      })
      .pipe(handleGraphQlErrors());
  }

  private stateChangedSubscription$(activeComponents: string[]) {
    return this.componentSubscription
      .subscribe({
        ids: activeComponents,
      })
      .pipe(handleGraphQlErrors());
  }

  private checkoutSynchronizedSubscription$(checkedOutComponents: string[]) {
    return this.checkoutSynchronizedSubscription
      .subscribe({
        component_ids: checkedOutComponents,
      })
      .pipe(handleGraphQlErrors());
  }
}

type LiveInstance = { right_id: uuid; left_id: uuid; id: uuid };
