import {
  Component,
  ComponentContainer,
  ComponentInstance,
  ComponentLastWrite,
  ComponentSubversion,
  ComponentVersion,
  Project,
  ProjectConfiguration,
  ProjectPermissions,
  ReleaseProcess,
  Session,
  Upload,
  UserSettings,
  Variant,
} from '@genetpdm/model';
import { EntityState } from '@ngrx/entity';
import { createReducer } from '@ngrx/store';
import { VariantAdapter, VariantsReducer } from '../variant';
import { UserInterfaceReducer } from '../@user-interface/user-interface.reducer';
import {
  ComponentAdapter,
  ComponentSubversionAdapter,
  ComponentVersionAdapter,
  ComponentInstanceAdapter,
  ProjectAdapter,
  ProjectConfigurationAdapter,
  ReleaseProcessAdapter,
} from './app.adapter';
import { ComponentsReducer } from './app.reducer';
import { Column } from '../../model/column';
import { ExplorerTreeNode } from '../../model/treenode';
import { SessionAdapter, SessionsReducer } from '../session';
import { ProjectsReducer } from '../project';
import { EnumsLocationEnum } from '@genetpdm/model/graphql';
import { SettingsReducer } from '../settings/reducer';
import { UploadAdapter } from '../upload/upload.adapter';
import { LoadUploadsReducer } from '../upload/upload.command';

export type AppState = {
  projects: EntityState<Project>;
  configs: EntityState<ProjectConfiguration>;
  selectedProjectId: uuid;
  permissions: ProjectPermissions;

  components: EntityState<Component>;
  componentVersions: EntityState<ComponentVersion>;
  componentSubversions: EntityState<ComponentSubversion>;
  instances: EntityState<ComponentInstance>;
  synchronizingComponentIds: uuid[];
  componentLastWriteTimes: ComponentLastWrite[];

  variants: EntityState<Variant>;
  expandedComponentIds: uuid[];
  selected: ExplorerTreeNode;
  selectedInstanceId: uuid;
  tabs: ComponentContainer[];
  selectedTabId: string;
  columns: Column[];
  favoriteComponentIds: uuid[];

  releaseProcesses: EntityState<ReleaseProcess>;

  selectedSessionId: uuid;
  sessions: EntityState<Session>;
  expandedSessionComponentIds: uuid[];

  currentUploadId: string;
  uploads: EntityState<Upload>;
  uploadProgress: number;
  expandedUploadComponentIds: uuid[];

  settings: UserSettings;

  isLoading?: boolean;
  error?: any;
};

export const InitialComponentCompositeState: AppState = {
  projects: ProjectAdapter.getInitialState(),
  configs: ProjectConfigurationAdapter.getInitialState(),
  selectedProjectId: '',
  permissions: new ProjectPermissions(),
  components: ComponentAdapter.getInitialState(),
  componentVersions: ComponentVersionAdapter.getInitialState(),
  componentSubversions: ComponentSubversionAdapter.getInitialState(),
  instances: ComponentInstanceAdapter.getInitialState(),
  synchronizingComponentIds: [],
  componentLastWriteTimes: [],
  variants: VariantAdapter.getInitialState(),
  expandedComponentIds: [],
  selected: null,
  selectedInstanceId: '',
  tabs: [
    {
      id: 'root',
      title: 'Root',
      icon: 'fas fa-home',
      componentIds: [],
      index: 0,
    },
    {
      id: 'checkedOut',
      title: 'Checked Out',
      icon: 'fas fa-user-alt',
      componentIds: [],
      index: 1,
    },
    {
      id: 'myParts',
      title: 'My Parts',
      icon: 'fas fa-user-alt',
      componentIds: [],
      index: 2,
    },
  ],
  selectedTabId: 'root',
  columns: [
    { field: 'last_sync', header: 'Last Synced' },
    { field: 'version', header: 'Version' },
    { field: 'route', header: 'Route' },
    { field: 'worker', header: 'Worker' },
    { field: 'updated_at', header: 'Last Edit' },
  ],
  favoriteComponentIds: [],
  releaseProcesses: ReleaseProcessAdapter.getInitialState(),

  selectedSessionId: '',
  sessions: SessionAdapter.getInitialState(),
  expandedSessionComponentIds: [],

  currentUploadId: '',
  uploads: UploadAdapter.getInitialState(),
  uploadProgress: 0,
  expandedUploadComponentIds: [],

  settings: {
    user_id: '',
    current_project_id: null,
    location: EnumsLocationEnum.Muc,
    data: null,
    user: {
      id: '',
      name: '',
    },
  },

  isLoading: false,
  error: null,
};

export const ExplorerStateReducer = createReducer(
  InitialComponentCompositeState,
  ...UserInterfaceReducer,
  ...ComponentsReducer,
  ...UserInterfaceReducer,
  ...VariantsReducer,
  ...SessionsReducer,
  ...LoadUploadsReducer,
  ...ProjectsReducer,
  ...SettingsReducer,
);
