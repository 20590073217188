import {
  CadComponentDataFragment,
  CadInstanceDataFragment,
  CadComponentFlatDataFragment,
  CadInstanceFlatDataFragment,
  VariantDataFragment,
  UploadComponentBlobDataFragment,
  CadComponentVersionDataFragment,
  CadComponentSubversionDataFragment,
  ReleaseProcessDataFragment,
  FileDataFragment,
  CadDrawingDataFragment,
  CadTaskDataFragment,
  UploadDataFragment,
} from '../@graphql/_generated';
import { Mutable, Prettify } from './utility/mutable-modifier';
import { CadGetInstancePositionsCompletedMessage } from '@genetsystems/genet-pdm-messaging-model';
import { from } from 'linq-to-typescript';
import { ComponentVariant } from './variant.model';
import { Routes } from './routes.constants';

/**
 * Defines a component with a certain version from the database
 */
export class ComponentInstanceVersion {
  /**
   * The latest version of the component
   */
  public latestVersion: ComponentVersion;

  /**
   * The latest subversion of the component
   */
  public latestSubversion: ComponentSubversion;

  /**
   * Gets the instance id
   */
  public get instance_id(): uuid {
    return this.instance?.id ?? '';
  }

  /**
   * Gets the component id
   */
  public get component_id(): uuid {
    return this.component?.id ?? '';
  }

  /**
   * Gets the instance name
   */
  public get instance_name(): string {
    return this.instance?.name ?? '';
  }

  /**
   * Gets the component name
   */
  public get component_name(): string {
    return this.component?.name ?? '';
  }

  /**
   * Gets the part number
   */
  public get part_number(): string {
    return this.latestVersion?.part_number;
  }

  /**
   * Gets the type of the component
   */
  public get component_type(): string {
    return this.component?.type;
  }

  /**
   * Gets the date when the component was last updated
   */
  public get updated_at(): Date {
    return this.latestVersion?.updated_at;
  }

  /**
   * Gets the current version of the component
   */
  public get version(): number {
    return this.latestVersion?.index;
  }

  /**
   * Gets the current subversion of the component
   */
  public get subversion(): number {
    return this.latestSubversion?.index;
  }

  /**
   * The route(path) of the component
   */
  public get route(): string {
    return this.latestVersion?.route;
  }

  /**
   * The route(path) where this component is checked out
   */
  public get check_out_route(): string {
    return this.latestVersion?.check_out_route;
  }

  /**
   * Gets any commens of the component
   */
  public get comments(): string {
    return this.latestVersion?.comments;
  }

  /**
   * Gets the current worker of the component
   */
  public get worker(): string {
    return this.latestVersion?.worker?.user?.name ?? '';
  }

  /**
   * Gets the nomenclature of this component
   */
  public get nomenclature(): string {
    return this.latestVersion?.nomenclature;
  }

  /**
   * Gets the definition of the component
   */
  public get definition(): string {
    return this.latestVersion?.definition;
  }

  /**
   * Gets the description of the component
   */
  public get description(): string {
    return this.latestVersion?.description;
  }

  /**
   * Gets the revision of the component
   */
  public get revision(): string {
    return this.latestVersion?.revision;
  }

  /**
   * Gets the maturity of the component
   */
  public get maturity(): string {
    return this.latestVersion?.maturity;
  }

  /**
   * Gets the date when the component was last edited
   */
  public get last_edit(): Date {
    return this.latestVersion?.updated_at;
  }

  /**
   * Gets the route(path) of the preview image of this component
   */
  public get previewRoute(): string {
    return Routes.getPrewiewImageRoute(this.component?.name ?? '');
  }

  /**
   * Gets the id of the component
   */
  public get key(): string {
    return this.instance?.id;
  }

  /**
   * Gets whether this component is the root component
   */
  public get is_root(): boolean {
    return this.component?.is_root;
  }

  /**
   * Gets whether this component is readonly
   */
  public get readonly(): boolean {
    return this.latestVersion?.readonly;
  }

  /**
   * Gets wheter this component is checked out
   */
  public get checked_out(): boolean {
    return this.latestVersion?.checked_out;
  }
  public get worker_is_current_user(): boolean {
    return (
      this.worker != '' &&
      this.currentUser != '' &&
      this.currentUser == this.worker
    );
  }
  // public get synchronizing(): boolean {
  //   return this.synchronizing;
  // }

  /**
   * Gets whether this component is frozen
   */
  public get frozen(): boolean {
    return this.latestVersion?.frozen;
  }

  /**
   * Gets wheter this component is positioned somewhere
   */
  public get is_positioned(): boolean {
    return this.instance?.position != null;
  }
  public get is_root_variant(): boolean {
    return true; //TODO
  }
  public get bounding_box_up_to_date(): boolean {
    return true; //TODO
  }
  public get tasks_attached(): boolean {
    return false; //TODO
  }
  public get files_attached(): boolean {
    return false; //TODO
  }
  public get collision_detected(): boolean {
    return false; //TODO
  }

  /**
   * Indicates whether this component has any errors
   */
  public has_error: false;

  /**
   * Returns the id of the parent instance of this component
   */
  public parent_instances: { id: uuid }[];

  /**
   *
   * @param component
   * @param instance
   * @param versions
   * @param subVersions
   * @param isExpanded
   * @param variant_name
   */
  constructor(
    public readonly component: Component,
    public readonly instance: ComponentInstance,
    public readonly versions: ComponentVersion[],
    public readonly subVersions: ComponentSubversion[],
    public isExpanded: boolean,
    public variant_name?: string,
    private currentUser: string = '',
    public readonly synchronizing: boolean = false,
    public readonly lastWriteTime: Date = new Date(1900, 1, 1),
  ) {
    this.latestVersion = from(versions)
      .orderByDescending((v) => v.index)
      .firstOrDefault();
    this.latestSubversion = from(subVersions)
      .where((v) => v.component_version == (this.latestVersion?.index ?? 0))
      .orderByDescending((v) => v.index)
      .firstOrDefault();
  }

  public getWithUpdatedCheckoutState(
    checkedOut: boolean,
  ): ComponentInstanceVersion {
    return new ComponentInstanceVersion(
      this.component,
      this.instance,
      this.versions.map((v) => {
        if (v.index == this.latestVersion.index) {
          return { ...v, checked_out: checkedOut };
        }
        return v;
      }),
      this.subVersions,
      this.isExpanded,
      this.variant_name,
    );
  }
}

/**
 * Data transfer object of a component.
 * This DTO is generated in response to a GraphQL query that retrieves data from the database.
 * It serves as a structured container for efficiently transferring data between the GraphQL server and the client application.
 */
export class ComponentInstanceVersionsDto {
  constructor(
    public readonly components: Component[],
    public readonly instances: ComponentInstance[],
    public readonly versions: ComponentVersion[],
    public readonly subversions: ComponentSubversion[],
    public readonly releaseProcesses: ReleaseProcess[],
  ) {}
}

/**
 * Data transfer object of a component attachment.
 * This DTO is generated in response to a GraphQL query that retrieves data from the database.
 */
export class ComponentAttachmentsDto {
  constructor(
    public readonly versions: ComponentVersion[],
    public readonly subversions: ComponentSubversion[],
    public readonly drawings: CadDrawing[],
    public readonly files: AttachedFile[],
    public readonly tasks: Task[],
    public readonly variants: ComponentVariant[],
  ) {}
}

//Here we are cloning the original types from hasura, make them mutable (no readonly fields anymore)
//and add fields if necessary. Prettify enables VSCode to show all properties while hovering with the cursor

export type ComponentInstance = Prettify<Mutable<CadInstanceDataFragment>>;

export type Component = Prettify<Mutable<CadComponentDataFragment>>;

export type ComponentVersion = Prettify<
  Mutable<CadComponentVersionDataFragment>
>;

export type ComponentSubversion = Prettify<
  Mutable<CadComponentSubversionDataFragment>
>;

export type ReleaseProcess = Prettify<Mutable<ReleaseProcessDataFragment>>;

export type ComponentFlat = Prettify<Mutable<CadComponentFlatDataFragment>>;

export type ComponentInstanceFlat = Prettify<
  Mutable<CadInstanceFlatDataFragment>
>;

export type Upload = Prettify<Mutable<UploadDataFragment>>;

export type UploadNode = {
  upload_id: string;
  upload_date: Date;
  variant_id: uuid;
};

export type UploadedComponentBlob = Prettify<
  Mutable<UploadComponentBlobDataFragment>
>;

export type Variant = Prettify<Mutable<VariantDataFragment>>;

export type RowChangeSet = {
  instance_id: uuid;
  rowindex: number;
};

export type InstancePositions =
  Prettify<CadGetInstancePositionsCompletedMessage>;

export type ComponentContainer = {
  id: string;
  title: string;
  icon: string;
  componentIds: uuid[];
  index: number;
};

export type AttachedFile = Prettify<Mutable<FileDataFragment>>;

export type CadDrawing = Prettify<Mutable<CadDrawingDataFragment>>;

export type Task = Prettify<Mutable<CadTaskDataFragment>>;

export type SynchronizingComponent = {
  component_id: uuid | null;
  route: string | null;
  version: number | null;
  synchronized: boolean | null;
  location: string | null;
  last_write_time: Date | null;
};

export type ComponentLastWrite = {
  component_id: uuid;
  last_write_time: Date;
}
