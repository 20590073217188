import { Injectable } from '@angular/core';
import { Store, createAction, createSelector, on, props } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import {
  CatiaService,
  ComponentMetadataService,
  LoggingService,
  ProjectService,
  SettingsService,
} from '../../services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  of,
  filter,
  tap,
  first,
  mergeMap,
  combineLatest,
  take,
  Observable,
} from 'rxjs';
import { GetUserUploadsQueryGQL } from '@genetpdm/model/graphql';
import { Upload } from '@genetpdm/model';
import { ActionFailed } from '../@user-interface/user-interface.actions';
import { ExplorerStoreReducerArray } from '../state-model';
import { UploadNodeFetcherComponent } from '../../../@shared/dialogs/upload-node-fetcher/upload-node-fetcher.component';
import {
  GetChildComponentInstanceVersions,
  GetComponentInstanceVersions,
  buildTreeWithRootNodes,
  selectAppState,
} from '../@app-state/app.selectors';
import { UploadAdapter } from './upload.adapter';
import { FileSystemObjectMessage } from '@genetsystems/genet-pdm-messaging-model';
import { FileApiExplorerComponent } from '../../../@shared/dialogs/file-api-explorer/file-api-explorer.component';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { LoadComponents } from '../@app-state/app.actions';
import { from } from 'linq-to-typescript';
import {
  ComponentAdapter,
  ComponentInstanceAdapter,
  ComponentSubversionAdapter,
  ComponentVersionAdapter,
} from '../@app-state/app.adapter';
import { IUploadViewModel } from '../../../modules/data-management/catiainput/catiainput.model';

// Actions

export const LoadUploads = createAction('[Component] Load Uploads');

export const LoadUpload = createAction(
  '[Component] Load Upload',
  props<{ uploadId: string }>(),
);

export const LoadUploadsSucceeded = createAction(
  '[Backend] Load Uploads Succeeded',
  props<{ uploads: Upload[] }>(),
);

export const LoadUploadSucceeded = createAction(
  '[Backend] Load Upload Succeeded',
  props<{ upload: Upload }>(),
);

export const OpenChooseUploadsDialog = createAction(
  '[Component] OpenChooseUploadsDialog',
);

export const ChooseUploadSucceeded = createAction(
  '[Component] ChooseUploadSucceeded',
  props<{ uploadId: string }>(),
);

export const OpenChooseCatFileDialog = createAction(
  '[Component] OpenChooseCatFileDialog',
);

export const StartCatiaImport = createAction(
  '[Component] StartCatiaImport',
  props<{ fileSystemObjects: FileSystemObjectMessage[] }>(),
);

export const StartTrackUploadProgress = createAction(
  '[Component] StartTrackUploadProgress',
  props<{ uploadId: string }>(),
);

export const TrackUploadProgress = createAction(
  '[Component] TrackUploadProgress',
  props<{ uploadId: string; progress: number }>(),
);

/**
 * Request to expand component nodes and load all children
 */
export const ExpandUploadComponents = createAction(
  '[Component] ExpandUploadComponents',
  props<{ componentIds: uuid[] }>(),
);

/**
 * Request to collapse component nodes
 */
export const CollapseUploadComponents = createAction(
  '[Component] CollapseUploadComponents',
  props<{ componentIds: uuid[] }>(),
);

// Effects

@Injectable()
export class LoadUploadsEffects {
  constructor(
    private getUploads: GetUserUploadsQueryGQL,
    private actions$: Actions,
    private store: Store,
    private catiaService: CatiaService,
    private settingsService: SettingsService,
    private projectService: ProjectService,
    private componentService: ComponentMetadataService,
    private dialogService: DialogService,
    private logger: LoggingService,
  ) {}

  public openChooseUploadsDialogSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenChooseUploadsDialog),
      map(() => LoadUploads()),
    ),
  );

  public openChooseUploadsDialogEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenChooseUploadsDialog),
      switchMap(
        () =>
          this.dialogService.open(UploadNodeFetcherComponent, {
            header: 'Choose a previous Upload',
            width: '70%',
          }).onClose,
      ),
      map((result) => ChooseUploadSucceeded({ uploadId: result.id })),
      catchError((error) =>
        of(
          ActionFailed({
            error: error,
            message: 'Could not load previous uploads.',
          }),
        ),
      ),
    ),
  );

  public chooseUploadSucceededSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChooseUploadSucceeded),
      switchMap((payload) =>
        this.store.select(selectUserUploads).pipe(
          map((uploads) => uploads.find((u) => u.id === payload.uploadId)),
          take(1),
        ),
      ),
      map((upload) =>
        LoadComponents({
          ids: upload.cad_components.map((c) => c.cad_component.id),
        }),
      ),
    ),
  );

  public loadUploadsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUploads),
      switchMap(
        () =>
          this.getUploads.watch(null, { fetchPolicy: 'no-cache' }).valueChanges,
      ),
      map((result) =>
        LoadUploadsSucceeded({ uploads: [...result.data.upload] }),
      ),
      catchError((error) =>
        of(
          ActionFailed({
            error: error,
            message: 'Could not load previous uploads.',
          }),
        ),
      ),
    ),
  );

  //Used after catia has uploaded the parts successfully
  public loadUploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUpload),
      switchMap((payload) =>
        this.getUploads
          .watch(null, { fetchPolicy: 'no-cache' })
          .valueChanges.pipe(
            map((result) =>
              LoadUploadSucceeded({
                upload: result.data.upload.find(
                  (u) => u.id == payload.uploadId,
                ),
              }),
            ),
          ),
      ),
      catchError((error) =>
        of(
          ActionFailed({
            error: error,
            message: 'Could not load previous uploads.',
          }),
        ),
      ),
    ),
  );

  public loadUploadSucceededEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUploadSucceeded),
      map((payload) => ChooseUploadSucceeded({ uploadId: payload.upload.id })),
    ),
  );

  public openChooseCatFileDialogEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenChooseCatFileDialog),
      switchMap(
        () =>
          this.dialogService.open(FileApiExplorerComponent, {
            header: 'Choose a Part/Product',
            width: '70%',
          }).onClose,
      ),
      filter((o) => o != null && o.length > 0),
      map((fileSystemObjects: FileSystemObjectMessage[]) =>
        StartCatiaImport({ fileSystemObjects: fileSystemObjects }),
      ),
      catchError((error) =>
        of(
          ActionFailed({
            error: error,
            message: 'Could not load previous uploads.',
          }),
        ),
      ),
    ),
  );

  public startCatiaImportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartCatiaImport),
      switchMap((request) => {
        const uploadId = moment(Date.now()).format('DD.MM.YYYY HH:mm:ss');
        const variantId = Guid.create();
        const readableUploadId = `${uploadId} ${request.fileSystemObjects[0].getFilename()}`;
        const project$ = this.projectService.getCurrentProject$();
        const user$ = this.settingsService.getUser$();

        return combineLatest([project$, user$]).pipe(
          tap((data) =>
            this.logger.logInfo(
              `Starting Catia Import for Project: ${data[0].name} & User ${data[1].name}`,
            ),
          ),
          first(),
          mergeMap((data) =>
            this.catiaService
              .startImportStructure$(
                readableUploadId,
                data[0]?.id?.toString() ?? Guid.EMPTY,
                variantId.toString(),
                `upload/${data[1].name}/${readableUploadId}`, // Change TargetRoutePrefix here if necessary
                request.fileSystemObjects.map((f) => f.getFullpath()),
              )
              .pipe(map((result) => result.getStructure())),
          ),
          take(1),
          map(() => readableUploadId),
        );
      }),
      map((uploadId) => StartTrackUploadProgress({ uploadId: uploadId })),
      catchError((error) =>
        of(
          ActionFailed({
            error: error,
            message: 'Could not import from catia.',
          }),
        ),
      ),
    ),
  );

  public startTrackUploadProgressEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartTrackUploadProgress),
      switchMap((request) =>
        this.fileUploadProgress$(request.uploadId).pipe(
          map((progress) =>
            TrackUploadProgress({
              uploadId: request.uploadId,
              progress: progress,
            }),
          ),
        ),
      ),
      catchError((error) =>
        of(
          ActionFailed({
            error: error,
            message: 'Could not track upload progress.',
          }),
        ),
      ),
    ),
  );

  public trackUploadProgressEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrackUploadProgress),
      tap((r) => console.log(r.progress)),
      filter((result) => result.progress == 100),
      take(1),
      map((payload) =>
        LoadUpload({
          uploadId: payload.uploadId,
        }),
      ),
      catchError((error) =>
        of(
          ActionFailed({
            error: error,
            message: 'Could not track upload progress.',
          }),
        ),
      ),
    ),
  );

  /**
   * Observe Upload Progress for upload ID. Returns number from 0-100.
   * @param uploadId
   */
  public fileUploadProgress$(uploadId: string): Observable<number> {
    const source$ = this.componentService
      .getUploadedComponentBlobs$(uploadId)
      .pipe(
        map(
          (data) =>
            100 * (data.filter((d) => d.md5_hash != null).length / data.length),
        ),
        tap((progress) =>
          this.logger.logTrace(`Upload progress: ${progress.toString()}`),
        ),
      );

    return source$;
  }
}

// Reducer

export const LoadUploadsReducer: ExplorerStoreReducerArray = [
  on(LoadUploads, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(LoadUpload, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(LoadUploadsSucceeded, (state, payload) => ({
    ...state,
    uploads: UploadAdapter.upsertMany(payload.uploads, state.uploads),
    isLoading: false,
  })),
  on(LoadUploadSucceeded, (state, payload) => ({
    ...state,
    uploads: UploadAdapter.upsertOne(payload.upload, state.uploads),
    isLoading: false,
  })),
  on(ChooseUploadSucceeded, (state, payload) => ({
    ...state,
    currentUploadId: payload.uploadId,
    isLoading: false,
  })),
  on(StartTrackUploadProgress, (state, payload) => ({
    ...state,
    currentUploadId: payload.uploadId,
    isLoading: true,
  })),
  on(TrackUploadProgress, (state, payload) => ({
    ...state,
    uploadProgress: payload.progress,
  })),
  on(ExpandUploadComponents, (state, payload) => ({
    ...state,
    expandedUploadComponentIds: from([
      ...state.expandedUploadComponentIds,
      ...payload.componentIds,
    ])
      .distinct()
      .toArray(),
  })),
  on(CollapseUploadComponents, (state, payload) => ({
    ...state,
    expandedUploadComponentIds: state.expandedUploadComponentIds.filter(
      (i) => !payload.componentIds.some((e) => e == i),
    ),
  })),
];

// Selectors

export const selectUserUploads = createSelector(selectAppState, (state) => {
  return UploadAdapter.getSelectors().selectAll(state.uploads);
});

export const selectCurrentUserUpload = createSelector(
  selectAppState,
  (state) => {
    return UploadAdapter.getSelectors()
      .selectAll(state.uploads)
      .find((u) => u.id == state.currentUploadId);
  },
);

export const selectCurrentUploadComponents = createSelector(
  selectAppState,
  selectCurrentUserUpload,
  (state, upload) => {
    if (!upload?.cad_components) {
      return [];
    }

    const componentIds = upload.cad_components
      .filter((c) => c.cad_component.is_root)
      .map((c) => c.cad_component.id);

    const componentMap = ComponentAdapter.getSelectors().selectEntities(
      state.components,
    );

    const components = from(componentIds)
      .select((id) => componentMap[id] ?? null)
      .toArray();

    return GetComponentInstanceVersions(
      componentIds,
      components,
      ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
      ComponentVersionAdapter.getSelectors().selectAll(state.componentVersions),
      ComponentSubversionAdapter.getSelectors().selectAll(
        state.componentSubversions,
      ),
      state.expandedUploadComponentIds,
      state.settings.user.name,
      state.synchronizingComponentIds,
      state.componentLastWriteTimes
    );
  },
);

export const selectVisibleUploadComponentInstanceVersions = createSelector(
  selectCurrentUploadComponents,
  selectAppState,
  (rootComponents, state) =>
    from(rootComponents)
      .concatenate(
        from(
          GetComponentInstanceVersions(
            state.expandedUploadComponentIds,
            ComponentAdapter.getSelectors().selectAll(state.components),
            ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
            ComponentVersionAdapter.getSelectors().selectAll(
              state.componentVersions,
            ),
            ComponentSubversionAdapter.getSelectors().selectAll(
              state.componentSubversions,
            ),
            state.expandedUploadComponentIds,
            state.settings.user.name,
            state.synchronizingComponentIds,
            state.componentLastWriteTimes
          ),
        ),
      )
      .concatenate(
        from(
          GetChildComponentInstanceVersions(
            state.expandedUploadComponentIds,
            ComponentAdapter.getSelectors().selectAll(state.components),
            ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
            ComponentVersionAdapter.getSelectors().selectAll(
              state.componentVersions,
            ),
            ComponentSubversionAdapter.getSelectors().selectAll(
              state.componentSubversions,
            ),
            state.settings.user.name,
            state.synchronizingComponentIds,
            state.componentLastWriteTimes
          ),
        ),
      )
      .toArray(),
);

export const selectUploadViewModel = createSelector(
  selectAppState,
  selectCurrentUploadComponents,
  selectVisibleUploadComponentInstanceVersions,
  (state, rootComponents, components) => {
    try {
      const tree = buildTreeWithRootNodes(rootComponents, components);

      const state_ = <IUploadViewModel>{
        uploads: UploadAdapter.getSelectors().selectAll(state.uploads),
        selectedUploadId: state.currentUploadId,
        nodes: tree.map((treeNode) => ({
          ...treeNode,
          origin: 'catiainput',
          uploadNodeId: state.currentUploadId,
        })),
        selectedNode: state.selected,
        isLoading: state.isLoading,
        error: state.error,
      };

      return state_;
    } catch (error) {
      console.error(error);
      return <IUploadViewModel>{
        uploads: UploadAdapter.getSelectors().selectAll(state.uploads),
        selectedUploadId: state.currentUploadId,
        nodes: [],
        selectedNode: state.selected,
        uploadProgress: state.uploadProgress,
        isLoading: state.isLoading,
        error: error,
      };
    }
  },
);
